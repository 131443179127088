.galeria {
    &__body {
        padding-bottom: 8%;
    }

    &__image {
        display: block;
        width: 100%;
        position: relative;
        overflow: hidden;
        height: 0;
        padding-bottom: 60%;
        background: $mainColor;
        margin-bottom: 30px;

        img {
            @include trans;

            position: absolute;
            top:0;
            left: 0;
            width: 100%;
            height: auto;
            min-height: 100%;
            opacity: 1;
        }

        .button {
            opacity: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%,0);
        }

        &:hover {
            .button {
                opacity: 1;
            }

            img {
                opacity: .3;
            }
        }
    }
}