.contacto {
    &__map {
        position: relative;
        overflow: hidden;
        height: 40vh;
        margin-bottom: 40px;

        @include mq(sm) {
            height: 25vw;
        }

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }
    }

    &__form {
        margin-bottom: 40px;

        .form {
            margin-bottom: 20px;
        }
        &__title {
            color: $blue;
            text-transform: uppercase;
            font-size: 20px;
            text-align: center;
            margin-bottom: 40px;

            @include mq(sm) {
                font-size: 28px;
                text-align: left;
            }
        }

        &__group {
            display: flex;
            margin-top: 20px;
        }

        &__phone {
            margin: 0 5px 0 0;
        }
    }
}
