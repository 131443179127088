.form{
    $f: &;

    &__group {
        position: relative;
        margin: 0 0 20px;

        &--center {
            text-align: center;
        }
    }

    &__input {
        @include trans;
        background: $white;
        border: 1px solid $lightGray;
        box-shadow: 0 0 0 rgba(0,0,0,0);
        width: 100%;
        height: 40px;
        padding: 0 15px;
        color: $darkGray;
        font-family: $mainFont;
        box-shadow: none;
        -webkit-appearance: none;
       -moz-appearance:    none;
       appearance:         none;

        @include mq(md) {
            height: 50px;
        }

        &::placeholder{
            @include trans;
            opacity:.8;
        }

        &:focus {
            outline: none;
            box-shadow: 0 0 10px rgba(0,0,0,.15);

            &::placeholder{
                opacity:0;
            }

            & + #{$f}__label {
                transform: translateY(0);
                opacity: 1;
                pointer-events: all;
            }
        }

        &--textarea {
            padding: 15px;
            height: 160px;
        }

        &--select {
            padding-left: 11px;
        }

        &--radio,
        &--checkbox {
            position: absolute;
            left: -9999px;

            &:checked {
                & + #{$f}__label {
                    &:after {
                        opacity: 1;
                        transform: scale3d(1,1,1);
                    }
                }
            }
        }

        &--file {
            position: absolute;
            left: -9999px;
        }
    }

    &__label{
        @include trans;

        font-size: 14px;
        font-weight: 900;
        text-transform: uppercase;
        display: block;
        color: $darkGray;
        margin-bottom: 10px;

        i {
            color: #c00;
        }

        &--checkbox {
            padding-left: 30px;
            position: relative;
            opacity: 1;
            pointer-events: all;
            top: 0;
            left: 0;
            font-size: 14px;
            text-transform: none;
            cursor: pointer;
            text-decoration: none;
            transform: translateY(0);

            &:before {
                content:'';
                position: absolute;
                width: 20px;
                height: 20px;
                background: $white;
                border: 1px solid $lightGray;
                border-radius: 3px;
                left: 0;
                top: calc(50% - 10px);
            }

            &:after {
                @include trans;

                position: absolute;
                content: '✔';
                color: $orange;
                opacity: 0;
                transform: scale3d(0,0,0);
                width: 20px;
                height: 20px;
                left: 0;
                top: calc(50% - 10px);
                font-size: 12px;
                line-height: 20px;
                text-align: center;
            }
        }

        &--radio {
            padding-left: 50px;
            position: relative;
            opacity: 1;
            pointer-events: all;
            top: 0;
            left: 0;
            font-size: 14px;
            text-transform: none;
            cursor: pointer;
            text-decoration: none;
            background: $white;
            border-radius: 5px;
            border: 1px solid $lightGray;
            padding-right: 15px;
            padding-top: 30px;
            padding-bottom: 30px;
            font-weight: 600;
            display: block;
            transform: translateY(0);
            font-size: 18px;

            span {
                color: $darkGray;
                display: block;
                font-size: 14px;
                margin-top: 3px;
            }

            &:before {
                content:'';
                position: absolute;
                width: 20px;
                height: 20px;
                background: $white;
                border: 1px solid $lightGray;
                border-radius: 3px;
                left: 15px;
                top: calc(50% - 10px);
                border-radius: 50%;
            }

            &:after {
                @include trans;

                position: absolute;
                content: '•';
                color: $orange;
                opacity: 0;
                transform: scale3d(0,0,0);
                width: 20px;
                height: 20px;
                left: 15px;
                top: calc(50% - 10px);
                font-size: 28px;
                line-height: 16px;
                text-align: center;
            }
        }

        &--file {
            opacity: 1;
            pointer-events: all;
            top: 0;
            display: flex;
            transform: translateY(0);
            width: 100%;
            position: static;
            text-transform: none;
            font-size: 16px;
            font-weight: 300;

            .txt {
                @include trans;
                background: $white;
                border: 1px solid $lightGray;
                border-radius: 5px;
                box-shadow: 0 0 0 rgba(0,0,0,0);
                flex-grow: 2;
                height: 40px;
                padding: 0 15px;
                color: $darkGray;
                margin-right: 10px;
                line-height: 38px;

                @include mq(md) {
                    height: 50px;
                    line-height: 48px;
                }
            }

            .btn {
                @include trans;

                display: inline-block;
                padding: 0 25px;
                text-decoration: none;
                cursor: pointer;
                white-space: nowrap;
                font-size: 16px;
                font-weight: 600;
                height: 40px;
                line-height: 36px;
                text-align: center;
                min-width: 120px;
                border:none;
                border: 2px solid $mainColor;
                background: transparent;
                color: $mainColor;

                &:hover {
                    background: rgba(0,0,0,.05);
                }

                @include mq(md) {
                    line-height: 46px;
                    height: 50px;
                }
            }
        }
    }

    &__disclaimer {
        color: $orange;
        font-size: 14px;
    }
}
