.fifty-fifty {
    &__image {
        position: relative;
        height: 0;
        width: 100%;
        overflow: hidden;
        padding-bottom: 80%;

        img{
            position: absolute;
            height: 100%;
            left: 0;
            width: auto;
            min-width: 100%;
            transform: scale3d(1.15,1.15,1.15);

            @include mq(sm) {
                transform: scale3d(1,1,1);
            }
        }
    }

    &__data {
        padding: 20px;

        @include mq(sm) {
            position: absolute;
            left:55%;
            top:50%;
            transform: translate3d(-50%,-50%,0);
        }
    }

    &__title {
        font-size: 28px;

        @include mq(md) {
            font-size: 36px;
        }
    }

    &__subtitle {
        font-size: 18px;
        margin: 0 0 40px;
    }

    &--img-left {
        .fifty-fifty__image {
            img {
                right: 0;
                left: 0;

                @include mq(sm) {
                    left: auto;
                    right: 0;
                }
            }
        }

        .fifty-fifty__data {
            @include mq(sm) {
                left:45%;
            }
        }
    }
}
