.footer {
    padding: 30px 0;
    border-top: 1px solid $lightGray;

    @include mq(md) {
        padding: 40px 0;
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include mq(md) {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    &__logo {
        display: inline-flex;
        justify-content: space-between;
        height: 40px;
        position: relative;
        margin-bottom: 30px;

        @include mq(md) {
            height: 60px;
            margin-bottom: 0;
        }

        &:after {
            position: absolute;
            right: 43%;
            width: 1px;
            top:0;
            bottom:0;
            background: $mainColor;
            content: '';

            @include mq(md) {
                right: 40%;
            }
        }

        img {
            width: auto;
            height: 100%;
            margin-right: 30px;
        }
    }

    &__actions {
        display: flex;
        align-items: center;

        @include mq(md) {
            right: 0;
            bottom: calc(100% + 25px);
            height: 60px;
        }
    }

    &__social {
        margin-right: 40px;
        display: flex;
        
        li {
            &:not(:last-child) {
                margin-right: 10px;
            }
        }

        a {
            color: $mainColor;
            font-size: 21px;
        }
    }
}
