.home {
    &__hero {
        padding-bottom: 100px;
        &__wrapper {
            @include mq(sm) {
                display: flex;
                width: 100%;
            }
        }

        &__column {
            width: 90%;
            margin-left: auto;
            margin-right: auto;
            // overflow: hidden;

            &--left {
                position: relative;
                height: 45vh;
                width: 100%;

                @include mq(sm) {
                    height: auto;
                    width: 55%;
                }

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: auto;
                }
            }

            &--right {
                @include mq(sm) {
                    width: 45%;
                    padding: 80px 0;
                }
            }
        }

        &__container {
            @include mq(sm) {
                width: 70%;
                margin-left: 40px;
            }
        }

        &__logo {
            width: 100%;

            img {
                width: 100%;
                height: auto;
            }
        }

        &__title {
            color: $white;
        }

        &__text {
            color: $white;
            margin-bottom: 20px;
        }
    }

    &__oportunidad {
        &__row {
            display: flex;
            flex-wrap: wrap;

            &--top {
                margin-bottom: 4%;
                padding-bottom: 4%;
                border-bottom: 1px solid $lightGray;
            }
        }

        &__container {
            display: flex;
            justify-content: center;
        }

        &__wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 40px;

            @include mq(sm) {
                margin-bottom: 0;
                display: inline-block;
            }
        }

        &__group {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            &:not(:last-child) {
                margin-bottom: 10px;
            }

            i {
                color: $blue;
                margin-right: 20px;
            }
        }

        &__title {
            margin: 0;
            color: $blue;
        }

        &__text {
            margin: 0;
            font-size: 14px;

            &--blue {
                color: $blue;
            }
        }

        &__button {
            width: 100%;
            padding: 0;

            @include mq(sm) {
                width: 200px;
                padding: 0;

                &:hover {
                    width: 220px;
                }
            }
        }

        &__column {
            margin-left: auto;
            margin-right: auto;
            position: relative;

            img {
                top: 0;
                left: 0;
                width: 100%;
                height: auto;
                margin-bottom: 40px;
            }

            @include mq(sm) {
                width: 100%;
            }
        }

        &__column-wrapper {
            @include mq(sm) {
                width: 75%;
                margin-left: 40px;
            }
        }

        &__wrapper-group {
            margin-top: 40px;
        }
    }

    &__quien-participa {
        padding: 12% 0;

        &__image {
            width: 100%;

            img {
                width: 100%;
                height: auto;
            }
        }
    }

    &__momentos {
        padding-top: 12%;
        // position: relative;

        &__brush-right {
            position: absolute;
            transform: translateY(-50%);
            right: 0;
            top: 50%;
            width: 70%;

            @include mq(sm) {
                width: auto;
                top: 33%;
            }
        }

        &__link {
            display: block;
            text-align: left;
            font-size: 30px;
        }

        &__text {
            text-align: left;
            margin-bottom: 40px;
        }

        &__wrapper {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 40px;
        }

        &__image {
            height: 0;
            overflow: hidden;
            position: relative;
            width: 100%;
            padding-bottom: 70%;
            display: block;

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
            }
        }

        &__slider {
            .slick-dots {
                display: flex;
                bottom: -30px;

                li.slick-active button:before,
                button:before {
                    color: $mainColor;
                }
            }
        }

        &__column {
            display: flex;
            justify-content: center;
            padding-top: 4%;
        }

        &__group {
            display: flex;
            align-items: center;

            @include mq(sm) {
                margin-bottom: 0;
            }

            i {
                margin-right: 10px;
                color: $blue;
                font-size: 30px;
            }
        }

        &__red {
            font-size: 16px;
        }
    }

    &__noticias {
        padding: 10% 0;
    }
}
