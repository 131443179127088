.activity {
    background: $white;
    border-radius: 10px;
    margin-bottom: 40px;

    &__image {
        width: 100%;
        border-radius: 10px 10px 0 0;
        position: relative;
        overflow: hidden;
        height: 0;
        padding-bottom: 100%;

        img {
            height: 100%;
            width: auto;
            min-width: 100%;
            position: absolute;
            top:50%;
            transform: translateY(-50%);
        }
    }

    &__data {
        padding: 20px 15px;
    }

    &__title {
        color: $mainColor;
        font-size: 16px;
        margin: 0 0 5px;
        text-transform: uppercase;
    }

    &__text {
        color: $darkGray;
        font-size: 14px;
        line-height: 1.5;
        margin-bottom: 20px;
    }
}
