@mixin headerLink() {
    color: $darkGray;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.5px;
    position: relative;
    text-transform: uppercase;
}

@mixin headerUnderline {
    &::after {
        @include trans;

        content:'';
        height: 2px;
        background: $mainColor;
        position: absolute;
        bottom: 0;
        width: auto;
        left:0;
        right:100%;
    }

    &:hover {
        color: $mainColor;

        &::after {
            right:0;
        }
    }
}

.header {

    $h: &;

    position: fixed;
    background: $white;
    width: 100%;
    z-index: 10000;

    @include mq(md) {
        position: relative;
    }

    &__hamburguer {
        cursor: pointer;
        width: 25px;
        height: 25px;
        margin: auto;
        position: absolute;
        right: 15px;
        top: 12px;

        span {
            background-color: $mainColor;
            border-radius: 2px;
            content: '';
            display: block;
            width: 100%;
            height: 2px;

            &:nth-child(1) {
                animation:outT 0.6s $easing backwards;
                animation-direction:reverse;
            }
            &:nth-child(2) {
                margin: 7px 0;
                animation:outM 0.6s $easing backwards;
                animation-direction:reverse;
            }
            &:nth-child(3) {
                animation:outBtm 0.6s $easing backwards;
                animation-direction:reverse;
            }
        }

        @include mq(md) {
            display: none;
        }
    }

    &__top {
        border-bottom: 1px solid $lightGray;
        padding: 10px 0;

        @include mq(md) {
            padding: 25px 0;
        }
    }

    &__logo {
        display: inline-flex;
        justify-content: space-between;
        height: 40px;
        position: relative;

        @include mq(md) {
            height: 60px;
        }

        &:after {
            position: absolute;
            right: 43%;
            width: 1px;
            top:0;
            bottom:0;
            background: $mainColor;
            content: '';

            @include mq(md) {
                right: 40%;
            }
        }

        img {
            width: auto;
            height: 100%;
            margin-right: 30px;
        }
    }

    &__actions {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include mq(md) {
            position: absolute;
            right: 0;
            bottom: calc(100% + 25px);
            height: 60px;
        }
    }

    &__social {
        display: flex;

        @include mq(sm) {
            margin-right: 40px;
        }

        li {
            &:not(:last-child) {
                margin-right: 10px;
            }
        }

        a {
            color: $mainColor;
            font-size: 21px;
        }
    }

    &__lang {
        display: flex;

        @include mq(sm) {
            margin-right: 40px;
        }

        li {
            &:not(:last-child) {
                margin-right: 10px;
            }

            &.-active {
                a {
                    color: $mainColor;

                    @include headerUnderline;

                    &:after {
                        right: 0;
                    }
                }
            }
        }

        a {
            @include headerLink;
            display: block;
            padding-bottom: 5px;
        }
    }

    &__nav {
        @include trans;

        position: fixed;
        top: 60px;
        left:0;
        right: 0;
        bottom: 0;
        width: 100%;
        transform: translateX(-100%);
        background: $white;
        padding: 20px 0;
        border-top: 1px solid $lightGray;

        @include mq(md) {
            position: static;
            border-top: none;
            padding: 0;
            transform: translateX(0);
        }
    }

    &__menu {
        padding-top: 20px;
        margin-top: 20px;
        border-top: 1px solid $lightGray;

        @include mq(md) {
            display: flex;
            width: 100%;
            justify-content: space-around;
            padding-top: 0;
            margin-top: 0;
            border-top: none;
        }

        li {

            &.-active {
                a {
                    color: $mainColor;
                    pointer-events: none;

                    &:after {
                        right: 0;
                    }
                }
            }

            a {
                @include headerLink();
                @include headerUnderline();

                position: relative;
                display: block;
                height: 50px;
                line-height: 50px;
                border-bottom: 1px dotted $lightGray;

                @include mq(md) {
                    border-bottom: none;
                }
            }
        }
    }

    &.-open {
        #{$h}__hamburguer {
            span:nth-child(1) {
                animation:inT 0.6s $easing forwards;
            }
            span:nth-child(2) {
                animation:inM 0.6s $easing forwards;
            }
            span:nth-child(3) {
                animation:inBtm 0.6s $easing forwards;
            }
        }

        #{$h}__nav {
            transform: translateX(0)
        }
    }

    & + main {
        padding-top: 60px;

        @include mq(md) {
            padding-top: 0;
        }
    }
}

//hamburguer animations
@keyframes inM{
50%{transform:rotate(0deg);}
100%{transform:rotate(45deg);}
}

@keyframes outM{
50%{transform:rotate(0deg);}
100%{transform:rotate(45deg);}
}

@keyframes inT{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(9px) rotate(0deg);}
100%{transform: translateY(9px) rotate(135deg);}
}

@keyframes outT{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(9px) rotate(0deg);}
100%{transform: translateY(9px) rotate(135deg);}
}

@keyframes inBtm{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(-9px) rotate(0deg);}
100%{transform: translateY(-9px) rotate(135deg);}
}
@keyframes outBtm{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(-9px) rotate(0deg);}
100%{transform: translateY(-9px) rotate(135deg);}
}
