.expositores {
    &__quienes-exponen {
        padding: 10% 0;

        &__text {
            color: $white;
            text-align: center;
        }

        &__image {
            width: 100%;

            img {
                width: 100%;
                height: auto;
            }
        }
    }

    &__plano {
        padding-bottom: 8%;

        &__text {
            text-align: center;
            margin-bottom: 40px;
        }

        &__image {
            width: 100%;
            margin-bottom: 40px;

            img {
                width: 100%;
                height: auto;
            }
        }

        &__button {
            margin-bottom: 100px;
        }

        &__column {
            display: flex;
            justify-content: center;
        }

        &__listado {
            border-top: 1px solid $lightGray;
            border-bottom: 1px solid $lightGray;
            padding: 40px 0;
        }

        &__listado-title,
        &__valores-title {
            color: $blue;
            margin-top: 0;
            text-transform: uppercase;
            font-weight: 900;
        }

        &__valores {
            padding: 40px 0;
        }

        &__valores-text {
            text-transform: uppercase;
            font-size: 14px;
        }

        &__list-item {
            padding-left: 20px;
            margin-bottom: 10px;
            position: relative;

            &:after {
                content:'•';
                color: $blue;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                font-size: 30px;
            }
        }

        &__stands-image {
            background: #f9f9f9;
            width: 100%;
            height: 0;
            padding-bottom: 100%;
            overflow: hidden;
            position: relative;
            margin-bottom: 20px;

            @include mq(sm) {
                margin-bottom: 0;
            }

            img {
                position: absolute;
                height: 50%;
                width: auto;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -50%, 0);
            }
        }
    }

    .fifty-fifty {
        &__data {
            margin-bottom: 40px;

            @include mq(sm) {
                margin-bottom: 0;
                width: 66%;
            }
        }
    }
}
