a.button,
button.button,
.button {
    @include trans;

    background: $link;
    color: $white;
    display: inline-flex;
    font-family: $mainFont;
    font-size: 14px;
    font-weight: 600;
    height: 50px;
    padding: 0 20px;
    text-align: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 2px solid $link;
    text-decoration: none;
    letter-spacing: 0.5px;
    text-transform: uppercase;

    &:hover{
        background: $linkHover;
        text-decoration: none;
        color: $white;
        padding: 0 30px;
        border: 2px solid $linkHover;
    }

    &:focus {
        outline: none;
        box-shadow: none;
    }

    &--white {
        background: $white;
        color: $link;
        border: 2px solid $white;

        &:hover{
            color: $linkHover;
            background: darken($white, 5%);
            border: 2px solid darken($white, 5%);
        }
    }

    &--ghost {
        background: $white;
        color: $link;

        &:hover{
            background: darken($white, 5%);
            color: $linkHover;
        }
    }

    i {
        margin-right: 10px;
    }
}
