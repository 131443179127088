.registro {
    &__hero {
        padding-bottom: 6%;

        &__wrapper {
            @include mq(sm) {
                display: flex;
                width: 100%;
            }
        }

        &__column {
            width: 90%;
            margin-left: auto;
            margin-right: auto;
            // overflow: hidden;

            &--left {
                position: relative;
                height: 45vh;
                width: 100%;

                @include mq(sm) {
                    height: auto;
                    width: 55%;
                }

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: auto;
                }
            }

            &--right {
                @include mq(sm) {
                    // width: 45%;
                    padding: 80px 0;
                }
            }
        }

        &__container {
            @include mq(sm) {
                width: 70%;
                margin-left: auto;
                margin-right: auto;
            }
        }

        &__logo {
            width: 100%;

            img {
                width: 100%;
                height: auto;
            }
        }

        &__title {
            color: $white;
        }

        &__text {
            color: $white;
            margin-bottom: 20px;
        }
    }

    &__oportunidad {
        padding-bottom: 8%;

        &__row {
            display: flex;
            flex-wrap: wrap;

            &--top {
                margin-bottom: 20px;
                padding-bottom: 20px;
                border-bottom: 1px solid $lightGray;
            }
        }

        &__column {
            &--left {
                height: 35vh;

                @include mq(sm) {
                    height: auto;
                }
            }
        }

        &__container {
            @include mq(sm) {
                display: flex;
                justify-content: space-between;
            }
        }

        &__wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 40px;

            @include mq(sm) {
                margin-bottom: 0;
                display: inline-block;
            }
        }

        &__group {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            &:not(:last-child) {
                margin-bottom: 10px;
            }

            i {
                color: $blue;
                margin-right: 10px;
            }
        }

        &__title {
            margin: 0;
            color: $blue;
        }

        &__text {
            margin: 0;
            font-size: 14px;

            &--blue {
                color: $blue;
            }
        }

        &__list {
            display: flex;
        }

        &__list-item {
            border: 4px solid $blue;
            border-radius: 50%;
            height: 65px;
            width: 65px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &:not(:last-child) {
                margin-right: 10px;
            }
        }

        &__number {
            font-size: 16px;
            font-weight: 900;
        }

        &__subtext {
            font-size: 8px;
            margin: 0;
            text-transform: uppercase;
        }

        &__column {
            width: 90%;
            margin-left: auto;
            margin-right: auto;
            position: relative;

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: auto;
            }

            @include mq(sm) {
                width: 100%;
            }
        }

        &__column-wrapper {
            @include mq(sm) {
                margin-left: 40px;
            }
        }

        &__wrapper-title {
            color: $blue;
            text-transform: uppercase;
        }

        &__wrapper-group {
            margin: 40px 0;
        }

        &__wrapper-text {
            margin-bottom: 20px;
        }
    }

    &__participar {
        padding: 4% 0;

        &__container {
            position: relative;
            margin-bottom: 40px;

            img {
                width: 100%;
                height: auto;
                z-index: -1;
            }
        }

        &__row {
            &--divider {
                border-bottom: 1px solid $lightGray;
                margin-bottom: 4%;
                padding-bottom: 4%;
            }
        }

        &__title {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
            margin: 0;
            color: $white;
            text-transform: uppercase;
            font-size: 24px;
            white-space: nowrap;

            @include mq(sm) {
                font-size: 36px;
            }
        }

        &__subtitle {
            color: $blue;
            text-transform: uppercase;
        }

        &__text {
            font-size: 14px;

            span {
                color: $blue;
            }
        }

        &__image {
            width: 100%;

            img {
                width: 100%;
                height: auto;
            }
        }

        &__blue-text {
            color: $blue;
            font-size: 16px;
            margin-bottom: 30px;
        }
    }
}
