.-hide {
    display: none!important;
}

.-hideOverflow {
    overflow: hidden;
}

.-static{
    position: static;
}

.-blur {
    filter: blur(7px);
}

.nopadding > [class*="col-"] {
     padding-left: 0 !important;
     padding-right: 0 !important;
}

.nopadding-xs > [class*="col-"] {
    @include mq(xs){
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

.nopadding-selective > .nopad-col {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.-vertical-space{
    padding-top: $base;
    padding-bottom: $base;

    &-top {
        padding-top: $base;
    }

    &-bottom {
        padding-bottom: $base;
    }
}

.embed-responsive.-map{
    padding-bottom: 56.25%;

    @include mq(sm){
        padding-bottom: 45%;
    }

    @include mq(md){
        padding-bottom: 30%;
    }
}

[data-parallax]{
    position: relative;
}

.border {
    border: 1px solid $lightGray;
    border-radius: 5px;
}

._divider {
    height: 1px;
    width: 100%;
    background: $lightGray;
    margin: 20px 0;
}

.polygon-bg {
    background: url('/images/polygon-back.jpg') no-repeat center center / cover;
}

.gray-bg {
    background: $lightestGray;
}

.ripped-border {
    padding-top: 8%;
    padding-bottom: 8%;
    margin-top: -1.5%;
    margin-bottom: -1.5%;
    position: relative;
    z-index: 2;

    &:before,
    &:after {
        content: '';
        position: absolute;
        display: block;
        width: 100%;
        left: 0;
        right:0;
        height: 0;
        padding-bottom: 10%;
    }

    &:before {
        top: 0;
        transform: translateY(-85%);
        background: url('/images/ripped-border-top.png') no-repeat center top / cover;

        @include mq(md) {
            transform: translateY(-40%);
        }
    }

    &:after {
        bottom: 0;
        transform: translateY(85%);
        background: url('/images/ripped-border-bottom.png') no-repeat center bottom / cover;

        @include mq(md) {
            transform: translateY(40%);
        }
    }

    &--gray {
        &:before {
            background: url('/images/ripped-border-gray-top.png') no-repeat center top / cover;
        }

        &:after {
            background: url('/images/ripped-border-gray-bottom.png') no-repeat center bottom / cover;
        }
    }

    &--top {
        padding-bottom: 0;
        margin-bottom: 0;

        &:after {
            display: none;
        }
    }

    &--bottom {
        padding-top: 0;
        margin-top: 0;

        &:before {
            display: none;
        }
    }
}

.section-title {
    font-size: 34px;
    margin: 0 0 20px;
    padding-bottom: 20px;
    background: url('/images/underline-blue.png') no-repeat center bottom;
    color: $mainColor;
    text-transform: uppercase;
    display: block;
    text-align: center;

    @include mq(md) {
        font-size: 52px;
        margin: 0 0 40px;
        padding-bottom: 30px;
    }

    &--white {
        background: url('/images/underline-white.png') no-repeat center bottom;
        color: $white;
    }

    &--left {
        text-align: left;
        background-position: left bottom;
    }
}

.main-text {
    color: $darkGray;
    margin: 0 0 10px;
    font-size: 16px;
    line-height: 1.5;

    &--large {
        font-size: 1.2em;

        @include mq(md) {
            font-size: 1.5em;
        }
    }
}
