.info {
    &__horarios {
        padding-bottom: 4%;
        border-bottom: 1px solid $lightGray;

        &__days {
            @include mq(sm) {
                display: flex;
                justify-content: space-between;
            }
        }

        &__day {
            width: 100%;
            max-width: 350px;
            margin: 0 auto 20px;
            border: 3px solid $mainColor;
            padding: 30px;
            text-align: center;

            @include mq(sm) {
                margin: 0 0 20px;
            }

            ._icon {
                color: $mainColor;
                font-size: 28px;
                margin-bottom: 20px;
            }

            ._day {
                text-transform: uppercase;
                color: $mainColor;
                font-size: 18px;
                margin: 0 0 3px;
                font-weight: 900;
            }

            ._hour {
                font-size: 14px;
                font-weight: 600;
                margin: 0 0 3px;
            }
        }
    }

    &__heading {
        padding: 40px 0;
    }

    &__title {
        text-transform: uppercase;
        font-size: 21px;
        margin: 0 0 5px;
        font-weight: 900;
        color: $mainColor;

        &--gray {
            color: $darkGray;
        }
    }

    &__subtitle {
        text-transform: uppercase;
        font-size: 14px;
        margin: 0;
        font-weight: 900;
    }

    &__text {
        font-size: 14px;
        margin: 0 0 3px;
    }

    &__ubicacion {
        padding: 4% 0;
        border-bottom: 1px solid $lightGray;

        &__mapa {
            position: relative;
            height: 0;
            overflow: hidden;
            padding-bottom: 60%;
            margin-top: 40px;

            @include mq(sm) {
                margin-top: 0;
            }

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                width: 100%;
                height: 100%;
                border: none;
            }
        }
    }

    &__parking {
        padding: 4% 0;

        &__mapa {
            position: relative;
            height: 0;
            overflow: hidden;
            padding-bottom: 60%;
            margin-bottom: 40px;

            @include mq(sm) {
                margin-bottom: 0;
            }

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: auto;
            }
        }
    }

    &__entradas {
        padding: 4% 0 8%;
        text-align: center;
        position: relative;

        &:before {

            @include mq(sm) {
                content: '';
                position: absolute;
                left: 0;
                background: url('/images/brush-left.png') no-repeat left center;
                width: 50%;
                top: 10%;
                bottom: 0;
            }
        }

        &:after {
            @include mq(sm) {
                content: '';
                position: absolute;
                right: 0;
                background: url('/images/brush-right.png') no-repeat right top;
                width: 50%;
                top: 0;
                bottom: 0;
            }
        }

        &__app {
            width: 90%;
            background: $white;
            box-shadow: 0 5px 10px 0 rgba(0,0,0,.10);
            max-width: 300px;
            margin: auto;
            border-radius: 7px;
            text-align: center;
            position: relative;
            z-index: 10;
        }

        &__app-heading {
            padding: 20px 0;
            border-bottom: 1px solid $lightGray;

            ._supertitle {
                text-transform: uppercase;
                font-size: 14px;
                font-weight: 900;
                width: 40%;
                display: inline-block;
                margin: 0;
            }
        }

        &__app-body {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 460px;
            padding: 15px;
            background: url('/images/cell.png') no-repeat center center / 200px;

            ._title {
                font-size: 28px;
                color: $mainColor;
                font-weight:900;
                text-transform: uppercase;
                margin: 0;
            }

            ._subtitle {
                font-size: 14px;
                font-weight:400;
                margin: 0;
            }
        }

        &__app-buttons-wrapper {
            display: flex;
            flex-direction: column;

            .button {
                justify-content: space-evenly;
                white-space: nowrap;

                img {
                    height: 90%;
                    width: auto;
                }

                &:first-child {
                    margin-bottom: 7px;
                }
            }
        }
    }

    &__warning {
        padding: 8% 0;
        background: url('/images/info-warning.jpg') no-repeat center center / cover;
        text-align: center;
        color: $white;

        &__icon {
            font-size: 32px;
            margin-bottom: 40px;
        }

        &__text {
            font-size: 16px;
            color: $white;
            line-height: 1.5;

            a {
                font-weight: 600;
                color: $white;
            }
        }
    }
}
