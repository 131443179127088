.visitantes {
    &__cta {
        padding-bottom: 4%;
        border-bottom: 1px solid $lightGray;

        &__content {
            text-align: center;

            @include mq(sm) {
                text-align: left;
                display: flex;
                justify-content: space-between;
            }

            p {
                @include mq(sm) {
                    margin-right: 15px;
                }
            }
        }
    }

    &__hoteles {
        padding: 8% 0;

        &__heading {
            padding: 0 0 40px;
        }
    
        &__title {
            text-transform: uppercase;
            font-size: 21px;
            margin: 0 0 5px;
            font-weight: 900;
            color: $mainColor;
        }

        &__list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }

    &__hotel {
        margin-bottom: 20px;
        width: 48%;

        @include mq(sm) {
            width: 33%;
        }

        @include mq(lg) {
            width: 18%;
        }
        
        &__image {
            width: 100%;
            height: 0;
            padding-bottom: 100%;
            position: relative;
            overflow: hidden;

            img {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: auto;
                min-width: 100%;
            }
        }

        &__data {
            font-size: 14px;
        }

        &__link {
            font-size: 12px;
            display: block;
            white-space: normal;
            margin-bottom: 5px;
        }
    }
}