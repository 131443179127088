.actividades {
    &__wrapper {
        padding-top: 4%;
        padding-bottom: 8%;
    }

    &__paneles,
    &__charlas,
    &__rueda-negocios,
    &__interactivas {
        background: $lightestGray;
        border-bottom: 1px solid $lightGray;
    }

    &__section-title {
        color: $blue;
        text-transform: uppercase;
        font-size: 20px;

        @include mq(sm) {
            font-size: 32px;
        }
    }

    &__date {
        color: $blue;
        text-transform: uppercase;
        font-size: 16px;

        @include mq(sm) {
            font-size: 24px;
        }
    }

    &__item {
        margin-bottom: 20px;

        span {
            display: inline-block;
            color: $blue;
            width: 15px;
        }
    }

    &__sublist {
        margin-top: 20px;
    }

    &__subitem {
        padding-left: 30px;
        position: relative;
        margin-bottom: 20px;

        &:after {
            content:'•';
            color: $blue;
            position: absolute;
            left: 15px;
            top: 50%;
            transform: translateY(-50%);
            font-size: 20px;
        }
    }
}
