.noticias {
    background: $lightestGray;
    &__wrapper {
        padding-top: 4%;
        padding-bottom: 8%;
    }

    &__body {
        background: $lightestGray;

        @include mq(md) {
            background: none;
        }
    }
}